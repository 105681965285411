import * as React from "react";
import Seo from "../../components/Seo";
import { Link } from "gatsby";
import LayoutNoMenu from "../../components/LayoutNoMenu";

const CinemaFineArt = () => {
  return (
    <LayoutNoMenu>
      <main className="cinemaScreen">
        <Seo
          title={`Kingston School of Art Foundation 2021 • Fine art showreel • Cinema`}
        />
        <div className="cinemaNav">
          <h1 className="cinemaNavItem selected">Fine art showreel</h1>

          <Link className="cinemaNavItem selected" to="/cinema/">
            Leave cinema
          </Link>
        </div>

        <iframe
          src="https://player.vimeo.com/video/555967126?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;autoplay=1"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          autoPlay="1"
          title="Fine art film"
          className="cinemaFilm"
        ></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>

        <div className="cinemaTitleBar">
          <Link className="cinemaNavItem desktop" to={`/cinema/bigbuild/`}>
            Screen 1
          </Link>
          <Link className="cinemaNavItem desktop" to={`/cinema/fashion/`}>
            Screen 2
          </Link>
          <Link className="cinemaNavItem desktop " to={`/cinema/viscom/`}>
            Screen 3
          </Link>
          <Link
            className="cinemaNavItem desktop selected"
            to={`/cinema/fineart/`}
          >
            Screen 4
          </Link>
          <Link className="cinemaNavItem mobile" to={`/cinema/bigbuild/`}>
            1
          </Link>
          <Link className="cinemaNavItem mobile" to={`/cinema/fashion/`}>
            2
          </Link>
          <Link className="cinemaNavItem mobile " to={`/cinema/viscom/`}>
            3
          </Link>
          <Link
            className="cinemaNavItem mobile selected"
            to={`/cinema/fineart/`}
          >
            4
          </Link>
        </div>
      </main>
    </LayoutNoMenu>
  );
};

export default CinemaFineArt;
